<template>
  <base-material-card
    icon="far fa-trophy-alt"
    title="Ergebnis"
    color="primary"
  >
    <p>Die Ergebnis-PDF, die von den Verantwortlichen unterschrieben und an den Ligaobmann geschickt werden muss.</p>
    <p>Zusätzlich wird häufig diese Liste ausgedruckt und den Vereinen mitgegeben.</p>
    <v-btn block @click="ergebnis(e, df, r, true)">Download</v-btn>
  </base-material-card>
</template>

<script>
import { usePdf } from '../../../plugins/pdf'

export default {
  name: 'ergebnis',

  setup (props, context) {
    return {
      ...usePdf(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
